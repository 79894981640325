import styled, { StyledComponent } from 'styled-components'

export const ExperienceCardHeader: StyledComponent<
  'header',
  any,
  {},
  never
> = styled.header`
  padding: 2em 2em 0em 2em;

  h2 {
    margin: 0;
  }
`
